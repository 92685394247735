<template>
  <!-- 分页功能的封装 -->
  <!-- @size-change 每页多少条(事件) -->
  <!-- @current-change 当前处于页码(事件) -->
  <!-- :current-page 当前处于页码 -->
  <div>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[1, 5, 10, 20,50,100]"
      :page-size="size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  props:['total','page','size'],
  data() {
    return {
    };
  },
  methods: {
    // 每页显示多少条数据所触发的回调
    handleSizeChange(Newsize) {
      this.$parent.selectSizeChange(Newsize);
      // 调用接口重新发起请求-----
    },
    // 
    handleCurrentChange(newPage) {
      this.$parent.selectPageChange(newPage);
      // 调用接口重新发起请求-----
    },
  },
};
</script>

<style lang="less" scoped>
</style>