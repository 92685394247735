<template>
  <el-form v-if="ruleForm" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px">
    <div v-show="pages === 1">
      <div class="isTitle">编辑客户个人资料</div>
      <el-form-item label="昵称:" prop="userName">
        <el-input
          v-model="ruleForm.userName"
          placeholder="请输入用户姓名"
          maxlength="10"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="性别：" prop="gender">
        <el-radio v-model="ruleForm.gender" :label="0">女</el-radio>
        <el-radio v-model="ruleForm.gender" :label="1">男</el-radio>
      </el-form-item>
      <el-form-item label="年龄：" prop="age">
        <el-input
          v-model.number="ruleForm.age"
          placeholder="用户年龄"
          maxlength="3"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="生日：" prop="birthday">
        <el-date-picker
          v-model="ruleForm.birthday"
          type="date"
          placeholder="请选择用户出生年月日"
          format="yyyy - MM - dd"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
          maxlength="20"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="身高：" prop="height">
        <div class="itemVal">
          <el-input
            v-model.number="ruleForm.height"
            placeholder="请输入用户身高"
            maxlength="3"
            clearable
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item label="体重：" prop="weight">
        <div class="itemVal">
          <el-input
            v-model.number="ruleForm.weight"
            placeholder="请输入用户体重"
            maxlength="3"
            clearable
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item label="居住地：" prop="state">
        <el-select v-model="state_1" placeholder="请选择" @change="selectArea1">
          <el-option
            v-for="item in area3"
            :key="item.text"
            :label="item.text"
            :value="item.text">
          </el-option>
        </el-select>

        <el-select
          v-model="state_2"
          collapse-tags
          style="margin-left: 20px;"
          placeholder="请选择"
          :disabled="isShowArea4" 
          @change="selectArea2">
          <el-option
            v-for="item in area4"
            :key="item.text"
            :label="item.text"
            :value="item.text">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="详情地址" prop="location">
        <el-input
          v-model="ruleForm.location"
          placeholder="请输入用户目前所在地"
          maxlength="8"
        ></el-input>
      </el-form-item>
      <el-form-item label="国籍：" prop="nationality">
        <el-input
          v-model="ruleForm.nationality"
          placeholder="请输入用户国籍"
          maxlength="8"
          clearable
        ></el-input>
      </el-form-item>

      <el-form-item label="祖籍：" prop="hometown">
        <el-select v-model="state_3" placeholder="请选择" @change="selectArea3">
          <el-option
            v-for="item in area5"
            :key="item.text"
            :label="item.text"
            :value="item.text">
          </el-option>
        </el-select>

        <el-select
          v-model="state_4"
          collapse-tags
          style="margin-left: 20px;"
          placeholder="请选择"
          :disabled="isShowArea5" 
          @change="selectArea4">
          <el-option
            v-for="item in area6"
            :key="item.text"
            :label="item.text"
            :value="item.text">
          </el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="祖籍：" prop="hometown">
        <el-input
          v-model="ruleForm.hometown"
          placeholder="请输入用户祖籍"
          maxlength="100"
        ></el-input>
      </el-form-item> -->
      <el-form-item label="职业：" prop="job">
        <el-input
          v-model="ruleForm.job"
          placeholder="请输入用户职业"
          maxlength="12"
          clearable
        ></el-input>
      </el-form-item>
      
      <div class="skip">
        <div class="next" @click="btnNext">下一页</div>
      </div>
    </div>
    <div v-show="pages === 2">
      <div class="isTitle">编辑客户个人资料</div>
      <el-form-item label="行业：" prop="job">
        <el-select
          v-model="ruleForm.profession"
          placeholder="请选择"
          @change="professionChange"
        >
          <el-option
            v-for="item in occupationData"
            :key="item.id"
            :label="item.profession"
            :value="item.profession"
          >
          </el-option>
        </el-select>
      </el-form-item>
      
      <el-form-item label="学历：" prop="education">
        <el-input
          v-model="ruleForm.education"
          placeholder="请输入用户学历"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="年收入：" prop="salary">
        <div class="itemVal">
          <el-input
            v-model.number="ruleForm.salary"
            placeholder="请输入用户年收入"
            clearable
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item label="婚姻状况：" prop="maritalStatus">
        <el-select
          :value="ruleForm.maritalStatus | marital(ruleForm.maritalStatus)"
          placeholder="请选择"
          @change="selectChange"
        >
          <el-option
            v-for="item in marriageList"
            :key="item.code"
            :label="item.value"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="情感经历：" prop="emotionalExp">
        <el-input
          type="textarea"
          v-model="ruleForm.emotionalExp"
          :rows="3"
          placeholder="请输入用户情感经历"
        ></el-input>
      </el-form-item>
      <el-form-item label="是否抽烟：" prop="smoke">
        <el-radio v-model="ruleForm.smoke" :label="0">不抽</el-radio>
        <el-radio v-model="ruleForm.smoke" :label="1">偶尔抽烟</el-radio>
        <el-radio v-model="ruleForm.smoke" :label="2">经常抽烟</el-radio>
      </el-form-item>
      <el-form-item label="是否喝酒：" prop="wine">
        <el-radio v-model="ruleForm.wine" :label="0">不喝</el-radio>
        <el-radio v-model="ruleForm.wine" :label="1">偶尔喝酒</el-radio>
        <el-radio v-model="ruleForm.wine" :label="2">经常喝酒</el-radio>
      </el-form-item>
      <el-form-item label="内心独白：" prop="declaration">
        <el-input
          type="textarea"
          v-model="ruleForm.declaration"
          :rows="3"
        ></el-input>
      </el-form-item>
      <el-form-item label="兴趣爱好：" prop="hobby" v-if="isShowHobby">
        <el-input
          v-model="ruleForm.hobby"
          @focus.stop="clickHobby"
        ></el-input>
      </el-form-item>
      <div class="skip">
        <div class="up" @click="btnUp">上一页</div>
        <div class="affirm" @click="submitAffirm('ruleForm')">确认</div>
      </div>
    </div>
  </el-form>
</template>

<script>
import { request } from "../../../API/request";
export default {
  props: {
    form: {
      type: Object,
    },
    state_1: {
      type: String,
    },
    state_2: {
      type: String,
    },
    state_3: {
      type: String,
    },
    state_4: {
      type: String,
    },
    disabledShow: {
      type: Boolean,
    },
    selectArr: {
      type: Array
    },
    isShowHobby: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      hobbyStr: '',
      disabled: true,
      ruleForm: null,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      pages: 1, // 页数

      state1: "",

      area3: [],
      area4: [],
      area5: [],
      area6: [],
      // value1: this.state_1,
      // value2: this.state_2,
      isShowArea4: true,
      isShowArea5: true,

      // 职业数据
      occupationData: [],
      marriageList: [
        {
          code: 1,
          value: "未婚",
        },
        {
          code: 2,
          value: "分居",
        },
        {
          code: 3,
          value: "丧偶",
        },
        {
          code: 4,
          value: "离异无孩",
        },
        {
          code: 5,
          value: "离异不带孩",
        },
        {
          code: 6,
          value: "离异带孩",
        },
      ],
      // 表单校检
      rules: {
        userName: [
          { required: true, message: "请输入用户姓名", trigger: "blur" },
        ],
        gender: [
          { required: true, message: "请选择用户性别", trigger: "blur" },
        ],
        birthday: [
          {
            required: true,
            message: "请选择用户出生年月日",
            trigger: "blur",
          },
        ],
        height: [
          { required: true, message: "请输入用户身高", trigger: "blur" },
          {
            type: "number",
            required: true,
            message: "用户身高必须为数字",
            trigger: "blur",
          },
        ],
        weight: [
          { required: true, message: "请输入用户体重", trigger: "blur" },
          {
            type: "number",
            required: true,
            message: "用户体重必须为数字",
            trigger: "blur",
          },
        ],
        state: [
          {
            required: true,
            message: "请选择用户的居住地",
            trigger: "blur",
          },
        ],
        location: [
          {
            required: true,
            message: "请输入用户的详情地址",
            trigger: "blur",
          },
        ],
        job: [{ required: true, message: "请输入用户职业", trigger: "blur" }],
        education: [
          { required: true, message: "请选择用户的学历", trigger: "blur" },
        ],
        salary: [
          { required: true, message: "请输入用户年收入", trigger: "blur" },
          {
            required: true,
            message: "用户年收入必须为数字",
            pattern: /^[+-]?(0|([1-9]\d*))(\.\d+)?$/,
            trigger: "blur",
          },
        ],
        maritalStatus: [
          { required: true, message: "请选择用户婚姻状况", trigger: "blur" },
        ],
        emotionalExp: [
          {
            required: true,
            message: "请输入用户的情感经历",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.getArea();
    this.getFindProfession();
  },
  methods: {
    professionChange(e) {
      this.ruleForm.profession = e;
    },
    getArea() {
      request({ url: "/area/selectAll", method: "GET" }).then((res) => {
        let arr = [];
        if (res.code == 200) {
          let data = res.data;
          for (let i = 0; i < data.length; i++) {
            const element = data[i];
            let text = element.所属州;
            if (text === "亚洲") {
              let children = [];
              for (let j = 0; j < element.国家.length; j++) {
                children.push({ text: element.国家[j] });
              }
              arr.push({ text, children: children });
            }
            if (text === "欧洲") {
              let children = [];
              for (let j = 0; j < element.国家.length; j++) {
                children.push({ text: element.国家[j] });
              }
              arr.push({ text, children: children });
            }
            if (text === "美洲") {
              let children = [];
              for (let j = 0; j < element.国家.length; j++) {
                children.push({ text: element.国家[j] });
              }
              arr.push({ text, children: children });
            }
            if (text === "非洲") {
              let children = [];
              for (let j = 0; j < element.国家.length; j++) {
                children.push({ text: element.国家[j] });
              }
              arr.push({ text, children: children });
            }
            if (text === "澳洲") {
              let children = [];
              for (let j = 0; j < element.国家.length; j++) {
                children.push({ text: element.国家[j] });
              }
              arr.push({ text, children: children });
            }
            if (text === "大洋洲") {
              let children = [];
              for (let j = 0; j < element.国家.length; j++) {
                children.push({ text: element.国家[j] });
              }
              arr.push({ text, children: children });
            }
          }
          this.area3 = arr;
          this.area5 = arr;
          console.log(this.area3, this.area5);
        }

        // if (res.code == 200) {
        //   this.area = res.data;
        // }
      });
    },
    // 现居国家第一个选择框
    selectArea1 (e) {
      this.ruleForm.state = ''
      this.area4 = []
      this.isShowArea4 = true
      this.area3.forEach((item) => {
        if (item.text === e) {
          this.area4.push(...item.children)
        }
      })
      this.ruleForm.state = this.state_1
      this.isShowArea4 = false
    },
    selectArea2 (e) {
      this.ruleForm.state = this.ruleForm.state.slice(0, 2)
      this.ruleForm.state = this.ruleForm.state + this.state_2
    },
    // // 现居国家第一个选择框
    selectArea3 (e) {
      this.ruleForm.hometown = ''
      this.area6 = []
      this.isShowArea5 = true
      this.area5.forEach((item) => {
        if (item.text === e) {
          this.area6.push(...item.children)
        }
      })
      this.ruleForm.hometown = this.state_3
      this.isShowArea5 = false
    },
    selectArea4 (e) {
      this.ruleForm.hometown = this.ruleForm.hometown.slice(0, 2)
      this.ruleForm.hometown = this.ruleForm.hometown + this.state_4
    },
    handleChange(e) {
      this.ruleForm.state = e[0] + " " + e[1];
    },
    getFindProfession() {
      request({ url: "/profession/findProfession", method: "GET" }).then(
        (res) => {
          if (res.code == 200) {
            this.occupationData = res.data;
          }
        }
      );
    },
    // 上一页
    btnUp() {
      this.pages = this.pages - 1;
    },
    // 下一页
    btnNext() {
      this.pages = this.pages + 1;
    },
    // 学历选择
    educationChange(e) {
      this.ruleForm.education = e;
    },
    // 婚姻状况选择
    selectChange(e) {
      this.ruleForm.maritalStatus = e;
    },
    selectDemand(e) {
      this.ruleForm.demand = e;
    },
    // 确认提交
    submitAffirm(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          this.disabled = true;
          console.log(this.ruleForm, "ruleFormruleForm");
          this.$emit("succeddEdit", this.ruleForm);
        } else {
          this.$message.error("信息填写有误，请检查！");
          return false;
        }
      });
    },
    clickHobby () {
      this.$emit('clickHobby', this.ruleForm.hobby)
    }
  },
  filters: {
    marital(item) {
      if (item === 1) {
        return "未婚";
      } else if (item === 2) {
        return "已婚";
      } else if (item === 3) {
        return "丧偶";
      } else if (item === 4) {
        return "离异无孩";
      } else if (item === 5) {
        return "离异不带孩";
      } else if (item === 6) {
        return "离异带孩";
      }
    },
    demand(item) {
      if (item === 1) {
        return "发广告";
      } else if (item === 2) {
        return "不发广告";
      } else if (item === 3) {
        return "只发朋友圈";
      }
    },
  },
  watch: {
    // 监听传值默认页数为一
    form(newVal) {
      this.pages = 1;
      this.ruleForm = newVal
    },
    selectArr(newVal) {
      console.log(newVal);
      if (newVal.length) {
        this.hobbyStr = ''
        for (const item of newVal) {
          this.hobbyStr += item.arr.text + ','
          console.log(this.hobbyStr);
          this.ruleForm.hobby = this.hobbyStr
        }
      }
    }
  },
};
</script>

<style lang="less" scoped>
.el-form {
  .el-form-item {
    margin-bottom: 18px;
    /deep/.el-form-item__label {
      font-size: 16px;
      text-align: start;
    }
    .itemVal {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .isTitle {
    font-size: var(--mainSize);
    text-align: center;
    margin-bottom: 12px;
  }
  .skip {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 12px;
    font-size: var(--minSize);
    color: var(--info);
    .next {
      cursor: pointer;
      margin-left: 20px;
      &:hover {
        color: var(--subPink);
      }
    }
    .up {
      cursor: pointer;

      &:hover {
        color: var(--subPink);
      }
    }
    .affirm {
      margin-left: 20px;
      padding: 5px 12px;
      cursor: pointer;
      background: var(--pink);
      color: var(--white);
      border-radius: 3px;
    }
  }
}
</style>