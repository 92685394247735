<template>
  <div style="display:flex;justify-content: space-between;" >
    <div>
      <el-input
        :placeholder="title"
        v-model="wxUsername"
        clearable
        @input="query"
        @change="query"
      ></el-input>
    </div>
    <div v-if="buttonShow"><el-button style="background:#ff7f7f;color:#ffffff" @click="diolog(0)">新建</el-button></div>
  </div>
</template>

<script>
import { debounce } from "../code/common";

export default {
  props:['title','buttonShow'],
  data() {
    return {
      wxUsername:"",
    };
  },
  mounted () {
    this.query();
  },
  methods: {
    // input 触发的事件 防抖
    query: debounce(function () {
      this.$emit('getUserList', this.wxUsername)
  
    }, 800),
    // 新增按钮
    diolog(data){
      this.$emit('diologModul',data)
    },
  },
};
</script>

<style lang="less" scoped>
.el-input {
  width: 200px;
  
}
</style>